function About() {
    const greeting = "Hey, I’m Olivia! ";
    const paragraph1 = "Born in Germany, raised in Norway. I love to capture the essence of the moment and transform it into something worth looking back on";
    const paragraph2 = "I strive for my work to feel Authentic, Expressive and Soulful. I love to communicate ideas through different mediums, from graphics, illustration, collages, sculptures, dancing and DJ’ing. But it has been the camera that interests me the most, as it engages me with the art of my surroundings. Exploring varied approaches and perspectives is something I seek for in my work, which allows me to tailor each project to meet its unique needs.";
    const paragraph3 = "I am currently studying Interaction Design at CODE University of applied Science. I have worked primarily with event photography and with music video-making as well as exhibited Berlin Design Week, Sub Scene and Henie Onstad Art Center.";
    const paragraph4 = "Now I’m open to seek new challenges. If you got curious and would like to know more ——>";

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#F277A4', borderRadius: '24px', padding: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
            <div style={{ width: '100%', maxHeight: '100%', overflow: 'auto', padding: '8px', boxSizing: 'border-box' }}>
                <p style={{ fontWeight: 'lighter', margin: '0 0 8px 0' }}><span style={{ fontWeight: 'bold' }}>{greeting}</span>{paragraph1}</p>
                <p style={{ fontWeight: 'lighter', margin: '0 0 8px 0' }}>{paragraph2}</p>
                <p style={{ fontWeight: 'lighter', margin: '0 0 8px 0' }}>{paragraph3}</p>
                <p style={{ fontWeight: 'lighter', margin: '0' }}>{paragraph4}</p>
            </div>
        </div>
    );
}

export default About;