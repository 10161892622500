import servicesPattern from '../../assets/graphics/servicesPattern.svg';



function Services() {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#97A560',
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${servicesPattern})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: '24px',
            padding: '12px'
        }}>
            <h1 style={{ marginLeft: '8px', fontSize: '3em', marginBottom: '-0em' }}>Services</h1>

            <h3 style={{ fontWeight: 'lighter', marginBottom: '-0.25em' }}>Portrait Photoshoots</h3>

            <h3 style={{ fontWeight: 'lighter', marginBottom: '-0.25em' }}>Event Photoshoot</h3>

            <h3 style={{ fontWeight: 'lighter' }}>Videography</h3>

        </div>
    )
}


export default Services;