import sphereRight from '../../assets/graphics/Sphere.svg';
import sphereLeft from '../../assets/graphics/Sphere-Left.svg';

function Header() {
    return (
        <div style={{ width: '100%', height: '88px', backgroundColor: '#F277A4', borderRadius: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', overflow: 'hidden' }}>
            <img src={sphereLeft} style={{ position: 'absolute', left: 0, height: '100%', objectFit: 'cover' }} />
            <h2 style={{ fontWeight: 'bold', fontSize: '1.5em', position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>OLIVIA HEYMANNS</h2>
            <img src={sphereRight} style={{ position: 'absolute', right: 0, height: '100%', objectFit: 'cover' }} />
        </div>
    );
}

export default Header;