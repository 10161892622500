import claimSymbol from '../../assets/graphics/Flower.svg';


function Claim() {
    return (
        <div style={{ width: '100%', height: '100%', borderRadius: '24px', backgroundColor: '#3F7462', display: 'flex', flexDirection: 'column', padding: '8px' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <img src={claimSymbol} alt='flower graphic icon' style={{ width: '112px', height: '112px' }} />
            </div>
            <div style={{ marginTop: '-1em' }}>
                <h2 style={{ fontSize: '2.75em', fontWeight: 'normal', marginBottom: '-0.5em' }}>Photographer & Designer</h2>
                <p style={{ fontSize: '1.5em', fontWeight: 'lighter' }}>Here to make your vision come to life</p>

            </div>
        </div>
    )
}


export default Claim;