import contactPattern from '../../assets/graphics/ContactPattern.svg'
import arrowButton from '../../assets/mini_arrow.png'

function Contact() {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#3F7462',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundImage: `url(${contactPattern})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: '24px',
            padding: '8px'
        }}>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h5 style={{ fontWeight: 'normal' }}>want to know more</h5>
                <img src={arrowButton} alt='chrome arrow icon' />
            </div>

            <h1 style={{ fontSize: '3em' }}>Contact Me</h1>
        </div>
    )
}

export default Contact;