import './App.css';
import "./fonts/titania.ttf";
import "./fonts/montserrat.ttf";

import LoadingScreen from './lib/components/LoadingScreen';
import { data } from './lib/data';
import * as THREE from 'three';
import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { Image, OrbitControls, Billboard, Text } from '@react-three/drei';
import { easing, geometry } from 'maath';
import { ANGLE_PER_IMAGE, TOTAL_IMAGES } from './lib/data';
import AboutButton from './lib/components/AboutButton';
import Logo from './lib/components/Logo';
import NextButton from './lib/components/NextButton';
import PrevButton from './lib/components/PrevButton';
import AboutSection from './lib/components/AboutSection/AboutSection';


extend(geometry);


function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const aboutSectionRef = useRef(null);
  const controlsRef = useRef();
  const [cameraPosition, setCameraPosition] = useState([0, 10, 10]);

  function handleLoad() {
    setTimeout(() => {
      console.log('loaded')
      setLoaded(true);
    }, 1000);
  }

  function scrollToAbout() {
    aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === TOTAL_IMAGES - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? TOTAL_IMAGES - 1 : prevIndex - 1));
  };

  const handleCardClick = (index) => {
    setCurrentImageIndex(index);
  };

  function handleCameraChange() {
    if (controlsRef.current) {
      const { x, y, z } = controlsRef.current.object.position;
      setCameraPosition([x, y, z]);

    }
  }

  return (
    <div>
      <div style={{ width: '100vw', height: 'auto', overflow: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Canvas
          dpr={[1, 1.5]}
          style={{ width: '100vw', height: '100vh' }}
          onCreated={handleLoad}
          camera={{ position: [0, 10, 10] }}
        >
          <OrbitControls ref={controlsRef} onChange={handleCameraChange} minDistance={5} maxDistance={25} enableZoom={true} enablePan={false} enableRotate={true} />
          <Scene position={[0, 0, 0]} currentImageIndex={currentImageIndex} onCardClick={handleCardClick} cameraPosition={cameraPosition} />
        </Canvas>
        {!loaded ? <LoadingScreen /> : null}


        <div className="logo-container">
          <Logo />
        </div>

        <div className="about-button-container">
          <AboutButton onClick={scrollToAbout} />
        </div>

        <div className="button-container">
          <div className="button-inner-container">
            <PrevButton onClick={handlePrevImage} />
            <NextButton onClick={handleNextImage} />
          </div>
        </div>


      </div>
      <div style={{ height: 'auto', margin: 0, }} ref={aboutSectionRef}>
        <AboutSection />
      </div>
    </div>
  );
}




function Scene({ currentImageIndex, onCardClick, cameraPosition, ...props }) {
  const ref = useRef();
  const targetRotation = useRef(0);

  const [hoveredImage, setHoveredImage] = useState(null);

  function findImageWithIndex(index) {
    for (const category of data) {
      for (const image of category.images) {
        if (image.index === index) {
          return image;
        }
      }
    }
    return null;
  }

  function getSectionByIndex(index) {
    for (const section of data) {
      const startIndex = section.startingIndex;
      const endIndex = startIndex + section.images.length - 1;
      if (index >= startIndex && index <= endIndex) {
        return section;
      }
    }
    return null;
  }

  useEffect(() => {
    const image = findImageWithIndex(currentImageIndex);
    if (image) {
      setHoveredImage(image);
      const section = getSectionByIndex(currentImageIndex);
      targetRotation.current = section.from * -1 + (currentImageIndex - section.startingIndex) * -ANGLE_PER_IMAGE;
    }
  }, [currentImageIndex]);

  useFrame((state, delta) => {
    state.events.update();
    easing.dampE(ref.current.rotation, [0, targetRotation.current, 0], 0.1, delta);
  });

  return (
    <group ref={ref} {...props}>
      {data.map((category) => {
        const len = ANGLE_PER_IMAGE * category.images.length;
        return (
          <Cards
            key={category.name}
            category={category.name}
            from={category.from}
            len={len}
            images={category.images}
            currentImageIndex={currentImageIndex}
            onCardClick={onCardClick}
            cameraPosition={cameraPosition}
          />
        );
      })}
      <ActiveCard image={hoveredImage} />
    </group>
  );
}

function Cards({ category, images, from = 0, len = Math.PI * 2, radius = 12.5, currentImageIndex, onCardClick, cameraPosition, ...props }) {
  const amount = images.length;
  const textPosition = from + (amount / 2 / amount) * len;
  const closestCardIndex = useRef(null);

  useEffect(() => {

    let minDistance = Infinity;
    images.forEach((image, i) => {
      const angle = from + (i / amount) * len;
      const cardX = Math.sin(angle) * radius;
      const distance = Math.abs(cardX - cameraPosition[0]);
      if (distance < minDistance) {
        minDistance = distance;
        closestCardIndex.current = i;
      }
    });
  }, [cameraPosition, images, from, len, radius]);

  return (
    <group {...props}>
      <Billboard position={[Math.sin(textPosition) * radius * 1.4, 0.5, Math.cos(textPosition) * radius * 1.4]}>
        {/* <Text font={"/font/font"} fontSize={0.25} anchorX="center" color="black">
          {category}
        </Text> */}
      </Billboard>
      {images.map((image, i) => {
        const angle = from + (i / amount) * len;
        const isVertical = image.orientation === 'vertical';
        const isActive = image.index === currentImageIndex;
        const isClosest = i === closestCardIndex.current;
        return (
          <Card
            key={angle}
            position={[Math.sin(angle) * radius, 0, Math.cos(angle) * radius]}
            rotation={[0, Math.PI / 2 + angle, 0]}
            active={isActive}
            url={image.src}
            isVertical={isVertical}
            cameraPosition={cameraPosition}
            isClosest={isClosest}
            onClick={(e) => {
              e.stopPropagation();
              onCardClick(image.index);
            }}
          />
        );
      })}
    </group>
  );
}

function Card({ url, active, hovered, isVertical, onClick, cameraPosition, isClosest, ...props }) {
  const ref = useRef();
  const verticalScale = [1, 1.618, 1];
  const horizontalScale = [1.618, 1, 1];

  useFrame((state, delta) => {
    const f = active ? 1.25 : 1;
    const cardX = props.position[0];
    const cameraX = cameraPosition[0];

    // Calculate the ratio of the camera's X position to the card's X position
    const ratioX = Math.abs(cardX / cameraX);

    const thresholdRatio = 0.5;

    if (ratioX < thresholdRatio) {
      const tiltAngle = (thresholdRatio - ratioX) * 1.5;
      const elevation = (thresholdRatio - ratioX) * 5;
      easing.damp3(ref.current.position, [0, elevation, 0], 0.1, delta);
      easing.damp3(ref.current.rotation, [0, tiltAngle, 0], 0.1, delta);
    } else {
      easing.damp3(ref.current.position, [0, 0, 0], 0.1, delta);
      easing.damp3(ref.current.rotation, [0, 0, 0], 0.1, delta);
    }

    easing.damp3(ref.current.scale, isVertical ? [verticalScale[0] * f, verticalScale[1] * f, verticalScale[2]] : [horizontalScale[0] * f, horizontalScale[1] * f, horizontalScale[2]], 0.15, delta);
  });

  return (
    <group {...props} onClick={onClick} raycast={() => null}>
      <Image ref={ref} transparent radius={0.075} url={url} scale={isVertical ? verticalScale : horizontalScale} side={THREE.DoubleSide} />
    </group>
  );
}

function ActiveCard({ image, ...props }) {
  const ref = useRef();
  const name = image ? image.name : '';
  const isVertical = image ? image.orientation === 'vertical' : false;
  const position = isVertical ? [0, 4.75, 0] : [0, 3.75, 0];

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.material.zoom = 0.75; // Initial zoom level before the effect
    }
  }, [image]);

  useFrame((state, delta) => {
    if (ref.current) {
      easing.damp(ref.current.material, 'zoom', 1, 0.5, delta); // Zoom in effect
    }
  });

  return (
    <Billboard {...props}>
      {name && (
        {/* <Text font={"/font/font"} fontSize={0.5} position={position} anchorX="center" color="black">
          {name}
        </Text> */}
      )}
      {image && (
        <Image
          ref={ref}
          transparent
          radius={0.3}
          position={[0, -0.5, 0]}
          scale={isVertical ? [3.5, 1.618 * 3.5, 0.2, 1] : [1.618 * 3.5, 3.5, 0.2, 1]}
          url={image.src}
        />
      )}
    </Billboard>
  );
}

export default App;