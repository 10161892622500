import behindCurtains from '../lib/assets/photos/portraits/behind_curtains.jpeg';
import birdsEyeView from '../lib/assets/photos/portraits/birds_eye.jpeg';
import byTheWindow from '../lib/assets/photos/portraits/by_the_window.jpeg';
import cloudWatching from '../lib/assets/photos/portraits/cloud_watching.jpeg';
import danceAlarm from '../lib/assets/photos/portraits/dance_alarm.jpeg';
import dots from '../lib/assets/photos/portraits/dots.jpeg';
import fakeSky from '../lib/assets/photos/portraits/fake_sky.jpeg';
import flowerPower from '../lib/assets/photos/portraits/flower_power.jpeg';

import forest from '../lib/assets/photos/portraits/forest.jpeg';
import funfair from '../lib/assets/photos/portraits/funfair.jpeg';
import funkyTime from '../lib/assets/photos/portraits/funky_time.jpeg';
import galentine from '../lib/assets/photos/portraits/galentine.jpeg';
import girliesInOrange from '../lib/assets/photos/portraits/girlies_in_orange.jpeg';
import groupPic from '../lib/assets/photos/portraits/group_pic.jpeg';
import introspection from '../lib/assets/photos/portraits/introspection.jpeg';
import love from '../lib/assets/photos/portraits/love.jpeg';
import motionOfTheNight from '../lib/assets/photos/portraits/motion_of_the_night.jpeg';
import munching from '../lib/assets/photos/portraits/munching.jpeg';
import observer from '../lib/assets/photos/portraits/observer.jpeg';
import orangeViseion from '../lib/assets/photos/portraits/orange_vision.jpeg';
import poarchModel from '../lib/assets/photos/portraits/poarch_model.jpeg';
import pose from '../lib/assets/photos/portraits/pose.jpeg';
import purpleLight from '../lib/assets/photos/portraits/purple_light.jpeg';
import saxMan from '../lib/assets/photos/portraits/sax_man.jpeg';
import shoulderToShoulder from '../lib/assets/photos/portraits/shoulder_to_shoulder.jpeg';
import sparkle from '../lib/assets/photos/portraits/sparkle.jpeg';
import stopLight from '../lib/assets/photos/portraits/stop_light.jpeg';
import sunkissed from '../lib/assets/photos/portraits/sunkissed.jpeg';
import talkToTheHand from '../lib/assets/photos/portraits/talk_to_the_hand.jpeg';
import theThrow from '../lib/assets/photos/portraits/the_throw.jpeg';
import thoughTheFlowers from '../lib/assets/photos/portraits/through_the_flowers.jpeg';
import visions from '../lib/assets/photos/portraits/visions.jpeg';
import warmSmiles from '../lib/assets/photos/portraits/warm_smiles.jpeg';


//EVENTS
import aboutToOrder from '../lib/assets/photos/events/about_to_order.jpeg';
import artSchool from '../lib/assets/photos/events/art_school.jpeg';
import aspiringCreator from '../lib/assets/photos/events/aspiring_creator.jpeg';
import baddie from '../lib/assets/photos/events/baddie.jpeg';
import badgalbuja from '../lib/assets/photos/events/badgalbuja.jpeg';
import bedazzled from '../lib/assets/photos/events/bedazzled.jpeg';
import blue from '../lib/assets/photos/events/blue.jpeg';
import carnivalForAll from '../lib/assets/photos/events/carnival_for_all.jpeg';
import celebration from '../lib/assets/photos/events/celebration.jpeg';
import cheersToSunsts from '../lib/assets/photos/events/cheers_to_sunsets.jpeg';
import clowningAround from '../lib/assets/photos/events/clowning_around.jpeg';
import collectiveCreativity from '../lib/assets/photos/events/collective_creativity.jpeg';
import dance from '../lib/assets/photos/events/dance.jpeg';
import eeeeh from '../lib/assets/photos/events/EEEEEh.jpeg';
import fashion from '../lib/assets/photos/events/fashion.jpeg';
import globo from '../lib/assets/photos/events/globo.jpeg';
import greenBlessing from '../lib/assets/photos/events/green_blessing.jpeg';
import inTheCrowd from '../lib/assets/photos/events/in_the_crowd.jpeg';
import intoTheSunset from '../lib/assets/photos/events/into_the_sunset.jpeg';
import kiteFestival from '../lib/assets/photos/events/kite_festival.jpeg';
import lightdance from '../lib/assets/photos/events/lightdance.jpeg';
import lightsCameraAction from '../lib/assets/photos/events/lights_camera_action.jpeg';
import lookingBack from '../lib/assets/photos/events/looking_back.jpeg';
import matching from '../lib/assets/photos/events/matching.jpeg';
import mcOnTheMic from '../lib/assets/photos/events/mc_on_the_mic.jpeg';
import mermaid from '../lib/assets/photos/events/mermaid.jpeg';
import paradeYoungster from '../lib/assets/photos/events/parade_youngster.jpeg';
import powerStance from '../lib/assets/photos/events/power_stance.jpeg';
import rhymesAndBlues from '../lib/assets/photos/events/rhymes_and_blues.jpeg';
import risiginBaloons from '../lib/assets/photos/events/rising_balloons.jpeg';
import rollingAround from '../lib/assets/photos/events/rolling_around.jpeg';
import sky from '../lib/assets/photos/events/sky.jpeg';
import spinning from '../lib/assets/photos/events/spinning.jpeg';
import spittingBars from '../lib/assets/photos/events/spitting_bars.jpeg';
import summernights from '../lib/assets/photos/events/summernights.jpeg';
import theKiteRunner from '../lib/assets/photos/events/the_kite_runner.jpeg';
import tritonsTrumpet from '../lib/assets/photos/events/tritons_trumpet.jpeg';
import vibrantNight from '../lib/assets/photos/events/vibrant_night.jpeg';
import whiteStrips from '../lib/assets/photos/events/white_strips.jpeg';
import windowPainting from '../lib/assets/photos/events/window_painting.jpeg';
import winning from '../lib/assets/photos/events/winning.jpeg';
import youngTalent from '../lib/assets/photos/events/young_talent.jpeg';


//SOURROUDINGS


import abandoned from '../lib/assets/photos/surroundings/abandoned.jpeg';
import airportWaits from '../lib/assets/photos/surroundings/airport_waits.jpeg';
import artObserver from '../lib/assets/photos/surroundings/art_observer.jpeg';
import atmosphere from '../lib/assets/photos/surroundings/atmosphere.jpeg';
import bananasJPG from '../lib/assets/photos/surroundings/bananasJPG.JPG';
import beyondHorizons from '../lib/assets/photos/surroundings/beyond_horizons.jpeg';
import burned from '../lib/assets/photos/surroundings/burned.jpeg';
import cafe from '../lib/assets/photos/surroundings/cafe.jpeg';
import creation from '../lib/assets/photos/surroundings/creation.jpeg';
import doener from '../lib/assets/photos/surroundings/doener.jpeg';
import ferryWindow from '../lib/assets/photos/surroundings/ferry_window.jpeg';
import foggyShopping from '../lib/assets/photos/surroundings/foggy_shopping.jpeg';
import fromTheDark from '../lib/assets/photos/surroundings/from_the_dark.jpeg';
import gameRoom from '../lib/assets/photos/surroundings/game_room.jpeg';
import gate from '../lib/assets/photos/surroundings/gate.jpeg';
import gaudi from '../lib/assets/photos/surroundings/gaudi.jpeg';
import groceries from '../lib/assets/photos/surroundings/groceries.jpeg';
import intoTheLight from '../lib/assets/photos/surroundings/into_the_light.jpeg';
import jump from '../lib/assets/photos/surroundings/jump.jpeg';
import laughs from '../lib/assets/photos/surroundings/laughs.jpeg';
import lifeByTheWater from '../lib/assets/photos/surroundings/life_by_the_water.jpeg';
import marketRoof from '../lib/assets/photos/surroundings/market_roof.jpeg';
import memorial from '../lib/assets/photos/surroundings/memorial.jpeg';
import milkTime from '../lib/assets/photos/surroundings/milk_time.jpeg';
import monsterMouth from '../lib/assets/photos/surroundings/monster_mouth.jpeg';
import playtime from '../lib/assets/photos/surroundings/playtime.jpeg';
import rainbowChaser from '../lib/assets/photos/surroundings/rainbow_chaser.jpeg';
import reflection from '../lib/assets/photos/surroundings/reflection.jpeg';
import rocks from '../lib/assets/photos/surroundings/rocks.jpeg';
import salve from '../lib/assets/photos/surroundings/salve.jpeg';
import studio from '../lib/assets/photos/surroundings/studio.jpeg';
import throughFlowers from '../lib/assets/photos/surroundings/through_flowers.jpeg';
import throughMyEyes from '../lib/assets/photos/surroundings/through_my_eyes.jpeg';
import toiletBreak from '../lib/assets/photos/surroundings/toilet_break.jpeg';
import uno from '../lib/assets/photos/surroundings/uno.jpeg';
import views from '../lib/assets/photos/surroundings/views.jpeg';
import yellow from '../lib/assets/photos/surroundings/yellow.jpeg';


const GAP = Math.PI / 16;
const TOTAL_GAP_SPACE = GAP * 3;
const AVAILABLE_SPACE = 2 * Math.PI - TOTAL_GAP_SPACE;
export const TOTAL_IMAGES = 112;
export const ANGLE_PER_IMAGE = AVAILABLE_SPACE / TOTAL_IMAGES;



export const data = [
    {
        name: 'portraits',
        from: 0,
        startingIndex: 0,
        images: [
            { orientation: 'vertical', src: poarchModel, index: 1 },
            { orientation: 'vertical', src: groupPic, index: 2 },
            { orientation: 'vertical', src: dots, index: 3 },
            { orientation: 'vertical', src: cloudWatching, index: 4 },
            { orientation: 'vertical', src: girliesInOrange, index: 5 },
            { orientation: 'vertical', src: love, index: 6 },
            { orientation: 'horizontal', src: birdsEyeView, index: 7 },
            { orientation: 'horizontal', src: stopLight, index: 8 },
            { orientation: 'vertical', src: munching, index: 9 },
            { orientation: 'vertical', src: behindCurtains, index: 10 },
            { orientation: 'horizontal', src: galentine, index: 11 },
            { orientation: 'horizontal', src: thoughTheFlowers, index: 12 },
            { orientation: 'vertical', src: flowerPower, index: 13 },
            { orientation: 'vertical', src: funkyTime, index: 14 },
            { orientation: 'vertical', src: introspection, index: 15 },
            { orientation: 'vertical', src: observer, index: 16 },
            { orientation: 'vertical', src: motionOfTheNight, index: 17 },
            { orientation: 'vertical', src: sparkle, index: 18 },
            { orientation: 'vertical', src: shoulderToShoulder, index: 19 },
            { orientation: 'horizontal', src: fakeSky, index: 20 },
            { orientation: 'vertical', src: saxMan, index: 21 },
            { orientation: 'horizontal', src: visions, index: 22 },
            { orientation: 'vertical', src: forest, index: 23 },
            { orientation: 'vertical', src: warmSmiles, index: 24 },
            { orientation: 'horizontal', src: theThrow, index: 25 },
            { orientation: 'vertical', src: talkToTheHand, index: 26 },
            { orientation: 'vertical', src: sunkissed, index: 27 },
            { orientation: 'horizontal', src: orangeViseion, index: 28 },
            { orientation: 'vertical', src: funfair, index: 29 },
            { orientation: 'vertical', src: danceAlarm, index: 30 },
            { orientation: 'vertical', src: pose, index: 31 },
            { orientation: 'vertical', src: byTheWindow, index: 32 },


            { orientation: 'horizontal', src: purpleLight, index: 22 },
        ],
    },
    {
        name: 'events',
        from: ANGLE_PER_IMAGE * 33 + GAP,
        startingIndex: 33,
        images: [
            { orientation: 'vertical', src: youngTalent, index: 33 },
            { orientation: 'vertical', src: mcOnTheMic, index: 34 },
            { orientation: 'horizontal', src: celebration, index: 35 },
            { orientation: 'vertical', src: bedazzled, index: 36 },
            { orientation: 'vertical', src: windowPainting, index: 37 },
            { orientation: 'vertical', src: lookingBack, index: 38 },
            { orientation: 'vertical', src: whiteStrips, index: 39 },
            { orientation: 'vertical', src: collectiveCreativity, index: 40 },
            { orientation: 'horizontal', src: kiteFestival, index: 41 },
            { orientation: 'horizontal', src: cheersToSunsts, index: 42 },
            { orientation: 'vertical', src: powerStance, index: 43 },
            { orientation: 'vertical', src: artSchool, index: 44 },
            { orientation: 'vertical', src: tritonsTrumpet, index: 45 },
            { orientation: 'vertical', src: winning, index: 46 },
            { orientation: 'vertical', src: carnivalForAll, index: 47 },
            { orientation: 'vertical', src: aspiringCreator, index: 48 },
            { orientation: 'vertical', src: matching, index: 49 },
            { orientation: 'vertical', src: spittingBars, index: 50 },
            { orientation: 'horizontal', src: theKiteRunner, index: 51 },
            { orientation: 'vertical', src: spinning, index: 52 },
            { orientation: 'vertical', src: summernights, index: 53 },
            { orientation: 'vertical', src: mermaid, index: 54 },
            { orientation: 'vertical', src: greenBlessing, index: 55 },
            { orientation: 'horizontal', src: risiginBaloons, index: 56 },
            { orientation: 'vertical', src: blue, index: 57 },
            { orientation: 'horizontal', src: intoTheSunset, index: 58 },
            { orientation: 'horizontal', src: sky, index: 59 },
            { orientation: 'horizontal', src: dance, index: 60 },
            { orientation: 'vertical', src: eeeeh, index: 61 },
            { orientation: 'vertical', src: lightdance, index: 62 },
            { orientation: 'vertical', src: aboutToOrder, index: 63 },
            { orientation: 'horizontal', src: clowningAround, index: 64 },
            { orientation: 'vertical', src: inTheCrowd, index: 65 },
            { orientation: 'horizontal', src: vibrantNight, index: 66 },
            { orientation: 'vertical', src: fashion, index: 67 },
            { orientation: 'horizontal', src: rollingAround, index: 68 },
            { orientation: 'vertical', src: baddie, index: 69 },
            { orientation: 'vertical', src: rhymesAndBlues, index: 70 },
            { orientation: 'horizontal', src: paradeYoungster, index: 71 },
            { orientation: 'horizontal', src: lightsCameraAction, index: 72 },
            { orientation: 'vertical', src: globo, index: 73 },
            { orientation: 'vertical', src: badgalbuja, index: 74 },
        ],
    },
    {
        name: 'surroundings',
        from: ANGLE_PER_IMAGE * 75 + 2 * GAP,
        startingIndex: 75,
        images: [
            { orientation: 'vertical', src: burned, index: 75 },
            { orientation: 'horizontal', src: ferryWindow, index: 76 },
            { orientation: 'horizontal', src: creation, index: 77 },
            { orientation: 'horizontal', src: artObserver, index: 78 },
            { orientation: 'horizontal', src: groceries, index: 79 },
            { orientation: 'vertical', src: rocks, index: 80 },
            { orientation: 'vertical', src: toiletBreak, index: 81 },
            { orientation: 'vertical', src: beyondHorizons, index: 82 },
            { orientation: 'horizontal', src: uno, index: 83 },
            { orientation: 'vertical', src: atmosphere, index: 84 },
            { orientation: 'vertical', src: jump, index: 85 },
            { orientation: 'horizontal', src: milkTime, index: 86 },
            { orientation: 'vertical', src: cafe, index: 87 },
            { orientation: 'vertical', src: gate, index: 88 },
            { orientation: 'horizontal', src: gaudi, index: 89 },
            { orientation: 'horizontal', src: lifeByTheWater, index: 90 },
            { orientation: 'horizontal', src: marketRoof, index: 91 },
            { orientation: 'vertical', src: playtime, index: 92 },
            { orientation: 'horizontal', src: yellow, index: 93 },
            { orientation: 'horizontal', src: salve, index: 94 },
            { orientation: 'horizontal', src: intoTheLight, index: 95 },
            { orientation: 'vertical', src: throughFlowers, index: 96 },
            { orientation: 'vertical', src: studio, index: 97 },
            { orientation: 'vertical', src: rainbowChaser, index: 98 },
            { orientation: 'vertical', src: bananasJPG, index: 99 },
            { orientation: 'horizontal', src: gameRoom, index: 100 },
            { orientation: 'vertical', src: foggyShopping, index: 101 },
            { orientation: 'vertical', src: memorial, index: 102 },
            { orientation: 'vertical', src: fromTheDark, index: 103 },
            { orientation: 'vertical', src: laughs, index: 104 },
            { orientation: 'horizontal', src: throughMyEyes, index: 105 },
            { orientation: 'horizontal', src: airportWaits, index: 106 },
            { orientation: 'horizontal', src: views, index: 107 },
            { orientation: 'vertical', src: reflection, index: 108 },
            { orientation: 'vertical', src: abandoned, index: 109 },
            { orientation: 'vertical', src: monsterMouth, index: 110 },
            { orientation: 'horizontal', src: doener, index: 111 },

        ],
    }
];