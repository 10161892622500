import Header from "./Header";
import Claim from "./Claim";
import Portrait from "./Portrait";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Socials from "./Socials";


function AboutSection() {
    return (
        <div className="wrapper">
            <div className="box1">
                <Header />
            </div>

            <div className="box2">
                <div className="content box-2-content">
                    <Claim />
                </div>
            </div>

            <div className="box3">
                <div className="content box-3-content">
                    <Portrait />
                </div>
            </div>

            <div className="box4">
                <div className="content box-4-content">
                    <About />
                </div>
            </div>

            <div className="box5">
                <div className="content box-5-content">
                    <Services />
                </div>
            </div>

            <div className="box6">
                <div className="content box-6-content">
                    <Contact />
                </div>
            </div>

            <div className="box7">
                <div className="content box-7-content">
                    <Socials />
                </div>
            </div>
        </div>
    );
}

export default AboutSection;