function Socials() {

    const SOCIALS = [
        {
            value: 'INSTAGRAM',
            link: 'https://www.instagram.com'
        },

        {
            value: 'YOUTUBE',
            link: 'https://www.youtube.com'
        },

        {
            value: 'NOTION',
            link: 'https://www.notion.so'
        },

    ]


    function openLink(link) {
        window.open(link, '_blank');
    }

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#F277A4', display: 'flex', justifyContent: 'space-around', borderRadius: '24px' }}>
            {SOCIALS.map((social, index) => {
                return (
                    <button style={{ background: 'none', border: 'none' }} key={index} onClick={() => openLink(social.link)}>
                        <h4 style={{ textDecoration: 'underline', fontWeight: 'lighter' }}>{social.value}</h4>
                    </button>
                )
            })}
        </div>
    )
}


export default Socials;