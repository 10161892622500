import ringButton from '../assets/ring_button.png'


function NextButton({ onClick }) {
    return (
        <button onClick={onClick} style={{ border: 'none', background: 'none', padding: 0 }}>
            <img style={{ width: '96px' }} src={ringButton} alt="Next Button" />
        </button>
    )
}


export default NextButton;