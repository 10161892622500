import about from '../assets/about_me.png'


function AboutButton({ onClick }) {
    return (
        <button onClick={onClick} style={{ border: 'none', background: 'none', padding: 0 }}>
            <img style={{ width: '256px' }} src={about} alt="About Button" />
        </button>
    )
}


export default AboutButton;