import { grid } from 'ldrs'

function LoadingScreen() {
    grid.register()

    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: 'white', position: 'absolute', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>

            <l-grid
                size="60"
                speed="1.5"
                color="black"
            ></l-grid>
        </div>
    )
}


export default LoadingScreen;
